import * as React from 'react'

const useMobileView = (initialValue = false): boolean => {
  const [isMobileView, setIsMobileView] = React.useState<boolean>(initialValue)

  React.useEffect(() => {
    const isMobileView = window.matchMedia('(max-width: 768px)').matches
      ? true
      : false

    setIsMobileView(isMobileView)
  })

  return isMobileView
}

export default useMobileView
