import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { msalConfig } from './authConfig'
import { MsalProvider } from '@azure/msal-react'
import { createBrowserHistory } from 'history'
import { PublicClientApplication } from '@azure/msal-browser/dist/app/PublicClientApplication'

export const history = createBrowserHistory()
const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Router history={history}>
        <App />
      </Router>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
