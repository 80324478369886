/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import React from 'react'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Tag } from '../../../models/Tag'

interface Props {
  options: Tag[]
  onChange: (values: string[]) => void
  values: string[]
  className: string
}

const SelectMultipleOrFreeSolo: React.FC<Props> = (props) => {

  return (
    <div className={props.className}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={props.options.map((option) => option.labelName)}
        freeSolo
        onChange={(event: any, values: string[]) => props.onChange(values)}
        value={props.values}
        defaultValue={['']}
        renderTags={(value: string[], getTagProps: any) =>
          value.map((option: string, index: number) => (
            <Chip variant="outlined" key={index} label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params: any) => (
          <TextField {...params} variant="filled" label="Övrig info (frivilligt)" placeholder="Tags" />
        )}
      />
    </div>
  )
}

export default SelectMultipleOrFreeSolo
