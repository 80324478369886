/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { ThemeProvider } from '@material-ui/core'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { IncomingOptions, Provider } from 'use-http'
import './App.scss'
import { AppContext } from './appContext'
import logo_src from './assets/klara_logo_white.png'
import { appConfig } from './authConfig'
import MainMenu from './components/common/MainMenu'
import MenuButton from './components/common/MenuButton'
import UserDropdown from './components/common/UserDropdown'
//import logo_src from './assets/gaia.png'
import MLAdminPageContainer from './components/pages/MLAdminPage/MLAdminPageContainer'
import DatabasePageContainer from './components/pages/DatabasePage/DatabasePageContainer'
import LandingPageContainer from './components/pages/LandingPage'
import ReviewPageContainer from './components/pages/ReviewPage/ReviewPageContainer'
import UploadPageContainer from './components/pages/UploadPage/UploadPageContainer'
import { getPages } from './custom-app-config'
import httpClient from './HttpClient'
import { Page } from './models/Page'
import theme from './utilities/materialTheme'

const App = (): JSX.Element => {
  const { instance, accounts } = useMsal()
  httpClient.getAccessToken = async function () {
    const account = instance.getAllAccounts()[0]
    if (!account) return null
    const response = await instance.acquireTokenSilent({
      scopes: appConfig.api.scopes,
      account: account
    })
    return response.accessToken
  }

  const httpOptions: IncomingOptions = {
    interceptors: {
      request: async ({ options, url, path, route }) => {
        if(options && options.headers) {
          options.headers = {...options.headers, 'Authorization' : `Bearer ${await httpClient.getAccessToken()}`}
        }
        return options
      }
    }
  }
  
  React.useEffect(() => {
    instance.handleRedirectPromise()
      .catch((error) => {
        console.log('error on redirectpromise', error)
      })
  },[])

  const handleLogin = () => {
    instance.loginRedirect(appConfig.api)
      .catch(e => {
        console.log(e)
      })
  }

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    })
  }

  const account: any = accounts[0]

  function hasRole (role: string) {
    return account?.idTokenClaims?.roles?.includes(role)
  }

  return (
    <AppContext.Provider value={{ account, hasRole }}>
      <Provider url={httpClient.apiBase} options={httpOptions}>
        <ThemeProvider theme={theme}>
          <div className="App" style={{height: '100vh'}}>
            <AuthenticatedTemplate>
              <div style={{display: 'flex', justifyContent: 'center', alignContent: 'flex-start'}}>
                <img src={logo_src} style={{width: 100}}></img>
              </div>
              <MainMenu>
                {(account?.idTokenClaims?.roles?.includes('superuser') || account?.idTokenClaims?.roles?.includes('klaraadmin') || account?.idTokenClaims?.roles?.includes('developer')) && (
                  getPages(account?.idTokenClaims?.roles).map((page: Page) => (
                    <MenuButton
                      key={page.name}
                      title={page.title}
                      disabled={page.disabled}
                      route={page.route}
                    />
                  ))
                )}
                <UserDropdown userName={account?.name} onLogout={handleLogout}/>
              </MainMenu>
              <div id="main-content" style={{height: '85%'}}>
                {(account?.idTokenClaims?.roles?.includes('superuser') || account?.idTokenClaims?.roles?.includes('klaraadmin') || account?.idTokenClaims?.roles?.includes('developer')) ?
                  <Switch>
                    <Route exact path='/'>
                      <UploadPageContainer />
                    </Route>
                    <Route exact path='/database'>
                      <DatabasePageContainer />
                    </Route>
                    <Route exact path='/review'>
                      <ReviewPageContainer />
                    </Route>
                    <Route exact path='/training' render={props => {
                      if(account?.idTokenClaims?.roles?.includes('klaraadmin') || account?.idTokenClaims?.roles?.includes('developer'))
                        return <MLAdminPageContainer />
                      else 
                        return <Redirect to={{ pathname: '/' }} />
                    }}>
                    </Route>
                  </Switch>
                  :
                  <Switch>
                    <Route exact path='/'>
                      <>
                        <UploadPageContainer />
                      </>
                    </Route>
                    <Redirect to='/' />
                  </Switch>
                }
              </div>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <Switch>
                <Route exact path='/'>
                  <LandingPageContainer onLogin={handleLogin}/>
                </Route>
                <Redirect to='/' />
              </Switch>
            </UnauthenticatedTemplate>
          </div>
        </ThemeProvider>
      </Provider>
    </AppContext.Provider>
  )
}

export default App