import { AccountInfo } from '@azure/msal-browser'
import React from 'react'

export type AppContextProps = {
  account: AccountInfo | null,
  hasRole: (role: string) => boolean
}

export const AppContext = React.createContext<AppContextProps>({
  account: null,
  hasRole: () => false
})
