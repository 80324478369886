import React, { useState } from 'react'
import { useEffect } from 'react'
import httpClient from '../../../HttpClient'
import ReviewPage from './ReviewPage'
import { UploadedImage } from '../../../models/UploadedImage'
import { Tag } from '../../../models/Tag'
import useFetch from 'use-http'

const ReviewPageContainer: React.FC = () => {

  const [imageData, setImageData] = useState<UploadedImage[]>([])
  const [imagesToRemove, setImagesToRemove] = useState<UploadedImage[]>([])

  const { loading: loadingLabels, error: errorLabels, data: labels= [] } = useFetch('labels/list', globalOptions => {
    return globalOptions
  }, []) 
  const { loading: loadingImages, error: errorImages, data: unapprovedImages = [] } = useFetch('images/unapproved', globalOptions => {
    return globalOptions
  }, []) 

  useEffect(() => {
    if(!loadingLabels && !loadingImages)
      mapImagesWithLabels()
  }, [loadingImages, loadingLabels])

  const mapImagesWithLabels = () => {
    unapprovedImages.map((i: UploadedImage) => {
      const labelList: Tag[] = labels
      i.removed = false
      const lbl = labelList.find((x: Tag) => i.labelId == x.labelId)
      i.label = lbl?.labelName || ''
    })
    setImageData(unapprovedImages)
  }

  const updateImageData = async () => {
    
    imagesToRemove.forEach(img => {
      updateImages(img)
      
      //  Ta bort från array
      const images = imageData
      const index = images.findIndex(i => i.filePath === img.filePath)
      if(index > -1) {
        images.splice(index, 1)
      }
      setImageData(images)
    })
    setImagesToRemove([])
  }

  const updateImages = async (img: UploadedImage) => {
    const lbl = img.newLabelId ? img.newLabelId : img.labelId
    const data = {
      IsApproved: true,
      LabelId: lbl
    }
    httpClient.patchWithHeaders(`images/${img.id}`, data)
  }

  const handleRemove = (img: UploadedImage, labelId: string) => {
    const images = imageData
    const index = images.findIndex(i => i.filePath === img.filePath)
    if(index > -1) {
      images[index].removed = true
      setImageData(images)
    
      const removeList = imagesToRemove
      if (img.labelId !== labelId)
        img.newLabelId = labelId
      removeList.push(img)
      setImagesToRemove(removeList)
    }
  }

  const handleUndo = (img: UploadedImage) => {
    const images = imageData
    const index = images.findIndex(i => i.filePath === img.filePath)
    if(index > -1) {
      images[index].removed = false
      setImageData(images)

      //Hitta i img to remove
      const removeList = imagesToRemove
      const removeIndex = imagesToRemove.findIndex(i => i.filePath === img.filePath)
      removeList.splice(removeIndex, 1)
      setImagesToRemove(removeList)
    }
  }

  return (
    <>
      <ReviewPage 
        loading={(loadingLabels || loadingImages)}
        loadingError={errorLabels != undefined || errorImages != undefined}
        data={imageData}
        updateImageData={updateImageData}
        labelOptions={labels}
        undoReview={handleUndo}
        onRemove={handleRemove}
      />
    </>
  )
}

export default ReviewPageContainer