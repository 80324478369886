import Resizer from 'react-image-file-resizer'

export const makeFolderBeautiful = (text: string): string => {
  if (!text) return ''
  const splitText = text.split('/')
  const lastFolderPath = splitText[splitText.length-2]
  const spaceText = lastFolderPath.split('-').join(' ')
  return spaceText.charAt(0).toUpperCase() + spaceText.slice(1)
}

export const resizeFile = (file: File, height: number, width: number, quality: number): unknown =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      height,
      width,
      'JPEG',
      quality,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export const imageExists = (image_url: string): boolean => {

  const http = new XMLHttpRequest()

  http.open('HEAD', image_url, false)
  http.send()

  return http.status != 404
}