/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Button, MenuItem, Select, TextField } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import ConfirmModal from '../UploadPage/ConfirmModal'
import { Category } from '../../../models/Tag'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        marginRight: '10px'
      },
      '& > * + *': {
        minWidth: '170px',
      },
    },
  }),
)

interface Props {
  onLabelAdded: (category: string, label: string) => void
  existingCategories: Category[]
  labelExists: boolean
  onLabelInputChanged: (v: string) => void
}

const CreateLabelForm: React.FC<Props> = (props) => {
  const [label, setLabel] = React.useState<string>('')
  const [category, setCategory] = React.useState<string>('')
  const [confirmModalOpen, setConfirmModalOpen] = React.useState<boolean>(false)

  const classes = useStyles()

  React.useEffect(() => {
    setCategory((props.existingCategories && props.existingCategories[0]) ? props.existingCategories[0]?.id : '')
  },[props.existingCategories])

  const handleLabelChange = (e: any) => {
    setLabel(e.target.value)
    props.onLabelInputChanged(e.target.value)
  }

  const handleCategoryChange = (e: any) => {
    setCategory(e.target.value)
  }

  const handleAddLabel = () => {
    setConfirmModalOpen(false)
    props.onLabelAdded(category, label)
  }

  return (
    <>
      <div >
        <h5>Lägg till klass</h5>
        <form className={classes.root} noValidate >
          <TextField label='Klass' 
            onChange={handleLabelChange} 
            error={props.labelExists}
            helperText={props.labelExists ? 'Namnet finns redan' : ''}></TextField>
          <Select 
            label='Kategori' 
            id={'category-select'}
            value={category || ''}
            onChange={handleCategoryChange}>
            {props.existingCategories && props.existingCategories.map((c: Category, index: number) => (
              <MenuItem
                value={c.id} key={index}
              >{c.categoryName}</MenuItem>
            ))}
          </Select>
          <Button variant= "outlined" color="primary" disabled={label === '' || props.labelExists} onClick={() => setConfirmModalOpen(true)}>Lägg till</Button>
        </form>
      </div>

      <ConfirmModal
        isOpen={confirmModalOpen}
        closeModal={() => setConfirmModalOpen(false)}
        title={'Lägg till klass'}
        body={'Vill du lägga till ny bildklass?'}
        cancelText={'Avbryt'}
        confirmText={'Lägg till'}
        handleConfirm={handleAddLabel}
      />
    </>
  )
}

export default CreateLabelForm