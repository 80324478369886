/* eslint-disable @typescript-eslint/no-explicit-any */
//import { LogLevel } from '@azure/msal-browser'

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: any = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URL
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {	
    loggerOptions: {	
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      loggerCallback: (level: any, message: any, containsPii: any) => {	
        if (containsPii) {		
          return		
        }	
        /*
        switch (level) {		
        case LogLevel.Error:		
          console.error(message)		
          return		
        case LogLevel.Info:		
          console.info(message)		
          return		
        case LogLevel.Verbose:		
          console.debug(message)		
          return		
        case LogLevel.Warning:		
          console.warn(message)		
          return		
        }	
        */
      }	
    }
  }
}

export const appConfig = {
  api: {
    host: process.env.REACT_APP_API_BASE || '',
    scopes: process.env.REACT_APP_API_SCOPES?.split(' ') || []
  }
}
