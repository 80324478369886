import { Page } from './models/Page'

export const getPages = (roles: string[]): Page[] => {

  if(!roles)
    return []
    
  let pages: Page[] = []

  if(roles.includes('superuser')) {
    pages = pages.concat(defaultPages)
  }
  
  if(roles.includes('klaraadmin') || roles.includes('developer')) {
    pages = defaultPages.concat(adminPages)
  }

  return pages
}

const defaultPages: Page[] = [
  {
    name: 'home',
    title: 'Hem',
    disabled: false,
    route: '/',
  },
  {
    name: 'database',
    title: 'Databas',
    disabled: false,
    route: '/database',
  },
  {
    name: 'review',
    title: 'Granska',
    disabled: false,
    route: '/review',
  }
]

const adminPages: Page[] = [
  {
    name: 'training',
    title: 'Klaras träning',
    disabled: false,
    route: '/training',
  }
]