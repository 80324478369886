import React from 'react'

import { Button, Icon, Select, MenuItem, FormControl, InputLabel, Paper, CircularProgress, Popover, ClickAwayListener, LinearProgress, Popper } from '@material-ui/core'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
import PublishIcon from '@material-ui/icons/Publish'
import { FileToUpload } from '../../../models/FileToUpload'
import useMobileView from '../../../hooks/useMobileView'
import classes from './UploadPage.module.scss'
import SelectMultipleOrFreeSolo from '../../common/FormControls/SelectMultipleOrFreeSolo'
import SwitchButton from '../../common/FormControls/SwitchButton'
import { Form } from 'react-bootstrap'
import ConfirmModal from './ConfirmModal'
import { Tag } from '../../../models/Tag'
import { resizeFile } from '../../../helpers/helperFunctions'
import { Model } from '../../../models/Model'
import { AppContext } from '../../../appContext'
import InfoIcon from '@material-ui/icons/Info'
import { UploadProgress } from '../../common/UploadProgress'

interface Props {
  onUploadFiles: (files: FileToUpload[]) => void
  onPredictFiles: (files: FileToUpload[], modelId: string) => void
  toggleUploadMode: (checked: boolean) => void
  onGetModelTags: (modelId: string, projectId: string) => void
  isUploading: boolean
  predictionMode: boolean
  tagOptions: Tag[]
  secondaryTagOptions: Tag[]
  predictionModels: Model[]
  uploadProgress: number | null
}

const UploadPage: React.FC<Props> = (props) => {

  const isMobileView = useMobileView()

  const [filesToUpload, setFilesToUpload] = React.useState<FileToUpload[]>([])
  const [selectedPrimaryTag, setSelectedPrimaryTag] = React.useState<Tag>(props.tagOptions[0] || {id: '', categoryName: '', name: '', categoryId: ''})
  const [selectedSecondaryTags, setSelectedSecondaryTags] = React.useState<string[]>([])
  const [confirmModalOpen, setConfirmModalOpen] = React.useState<boolean>(false)
  const [showValidationText, setShowValidationText] = React.useState<boolean>(false)
  const [informationHoverIsOpen, setInformationHoverIsOpen] = React.useState<boolean>(false)
  const [informationPopupIsOpen, setInformationPopupIsOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [hoverModel, setHoverModel] = React.useState<string>('')
  const imgFormRef = React.useRef<HTMLFormElement>(null)
  const appContext = React.useContext(AppContext)

  React.useEffect(() => {
    clearForm()
  }, [props.predictionMode])

  const handleSubmit = () => {
    if(filesToUpload.length < 1) {
      setShowValidationText(true)
    } else {
      setConfirmModalOpen(true)
    }
  }
  
  const handleConfirmSubmit = () => {
    
    // set current tags
    filesToUpload.forEach((file: FileToUpload) => {
      file.primaryTag = selectedPrimaryTag,
      file.secondaryTags = selectedSecondaryTags
    }) 

    props.onUploadFiles(filesToUpload)
    clearForm()
  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilesChange = async (event: any) => {
    const currentList = []
    const files = event.target.files
    
    let it = files.length

    for(const file of files) {

      const resizedImage = await resizeFile(file, 2000, 2000, 72) as File

      const img: FileToUpload = {
        filename: resizedImage.name,
        file: resizedImage,
        objectURL: URL.createObjectURL(resizedImage),
        key: it,
        primaryTag: {labelId: '', categoryName: '', labelName: '', categoryId: ''},
        secondaryTags: []
      } 
      currentList.push(img)
      it++
    }
    setFilesToUpload(filesToUpload.concat(currentList))
    setShowValidationText(currentList.length === 0)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPredictFiles = async (event: any) => {

    const currentList = []
    const files = event.target.files

    let it = filesToUpload.length
    
    for(const file of files) {

      const resizedImage = await resizeFile(file, 2000, 2000, 72) as File

      const img: FileToUpload = {
        filename: resizedImage.name,
        file: resizedImage,
        objectURL: URL.createObjectURL(resizedImage),
        key: it,
        primaryTag: {labelId: '', categoryName: '', labelName: '', categoryId: ''},
        secondaryTags: []
      } 
      currentList.push(img)
      it++
    }
    setFilesToUpload(currentList)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTagChange = (e: any) => { //TODO ändra till använda e.target.index
    const tag = props.tagOptions.find(obj => obj.labelId === e.target.value)
    setSelectedPrimaryTag(tag || props.tagOptions[0])
  }

  const handleSecondaryTagChanged = (values: string[]) => {
    setSelectedSecondaryTags(values)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModelSelect = (e: any) => {
    const modelId = e.currentTarget.value
    props.onPredictFiles(filesToUpload, modelId)
  }
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hoverModelInfoEnter = (e: any) => {
    if(!informationPopupIsOpen) {
      setHoverModel(e.currentTarget.id)
      toggleInformationHoverBox(e, true)
    }
  }
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hoverModelInfoLeave = (e: any) => {
    if(!informationPopupIsOpen) {
      toggleInformationHoverBox(e, false)
    }
  }

  const handleGetModelInfo = (e: any) => {
    const obj = JSON.parse(e.currentTarget.value)
    const model = obj as Model
    const projectId = model.projectId
    props.onGetModelTags(model.id, projectId)
  }

  const clearForm = () => {
    clearFileInputs()
    setFilesToUpload([])
    setSelectedPrimaryTag(props.tagOptions[0])
    setSelectedSecondaryTags([])
    setConfirmModalOpen(false)
    setShowValidationText(false)
  }
  const clearFileInputs = () => {
    if(document) {
      const e1 = document.getElementById('desktop-image-input') as HTMLInputElement 
      const e2 = document.getElementById('camera-upload')  as HTMLInputElement 
      const e3 = document.getElementById('mobile-image-input') as HTMLInputElement 
      if(e1) e1.value = ''
      if(e2) e2.value = ''
      if(e3) e3.value = ''
    }
  }
 
  // display selected, not yet uploaded files
  const displaySelectedFiles = () => (
    <div className={classes.preview}>
      {filesToUpload.map((file: FileToUpload) => (
        <img key={file.key} src={file.objectURL} />
      ))}
    </div>
  )
  
  const displayTagging = () => (
    <Form className={classes.tagging}>
      <Form.Group style={{margin: '20px 0px'}}>
        <FormControl>
          <InputLabel>Primär kategori</InputLabel>
          <Select 
            className={classes.formInput}
            onChange={handleTagChange} 
            value={selectedPrimaryTag?.labelId || ''}>
            {props.tagOptions.map((option: Tag) => (
              <MenuItem 
                value={option.labelId} key={option.labelId}>{option.labelName}</MenuItem>
            )
            )}
          </Select>
        </FormControl>
      </Form.Group>

      <Form.Group>
        <FormControl>
          <SelectMultipleOrFreeSolo 
            className={classes.formInput}
            //options={props.secondaryTagOptions.filter((tag) => tag.parentName === selectedPrimaryTag.parentName)} 
            options={props.secondaryTagOptions} 
            onChange={handleSecondaryTagChanged}
            values={selectedSecondaryTags}/>
        </FormControl>
      </Form.Group>
    </Form>
  )

  const displayFileUpload = () => (
    <>
      <Form ref={imgFormRef}>
        {!isMobileView &&
        <div className={classes.fileInputHolder}>
          <div>
            <Icon component={PublishIcon}></Icon>
            <p>Drag file or click to upload</p>
          </div>
          <input type="file" id='desktop-image-input' onChange={props.predictionMode ? onPredictFiles : onFilesChange} multiple />
        </div>
        }
        {isMobileView &&
        <div className={classes.mobileFileInputHolder}>
          <Button variant="outlined" color="primary">
            <Icon component={AddAPhotoIcon} fontSize="large" color="primary"></Icon>
            <input type="file" id={'camera-upload'} onChange={props.predictionMode ? onPredictFiles : onFilesChange} capture="filesystem" multiple /> {/** Opens camera */}
          </Button>
          <Button variant="outlined" color="primary">
            <Icon component={InsertPhotoIcon} fontSize="large" color="primary"></Icon>
            <input type="file" id={'gallery-upload'} onChange={props.predictionMode ? onPredictFiles : onFilesChange} accept="image/*" multiple /> {/** Opens gallery */}
          </Button>
        </div>
        }
      </Form>
      {
        showValidationText && 
        <p style={{color: 'red'}}>Välj minst en bild</p>
      }
    </>
  )

  // display upload form
  const DisplayUploadForm = () => (
    <div className={classes.form}>
      {displayFileUpload()}
      {displaySelectedFiles()}
      {displayTagging()}

      <div className={classes.formButtons}>
        <Button
          onClick={clearForm}
          variant="outlined" color="primary">
        Rensa
        </Button>
        <Button 
          onClick={handleSubmit} 
          disabled={props.isUploading} 
          variant="contained" color="primary">
          Ladda upp
        </Button>
      </div>
    </div>
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleInformationBox = (event: any) => {
    if(informationHoverIsOpen)
      setInformationHoverIsOpen(false)
    if(!informationPopupIsOpen) {
      if(event.currentTarget.id !== 'info')
        return
      setAnchorEl(event.currentTarget)
      setInformationPopupIsOpen(true)
    } else {
      setAnchorEl(null)
      setInformationPopupIsOpen(false)
      setHoverModel('')
    }
  }


  const toggleInformationHoverBox = (event: React.MouseEvent<HTMLButtonElement>, open: boolean) => {
    if(open) {
      setAnchorEl(event.currentTarget)
      setInformationHoverIsOpen(true)
    } else {
      setAnchorEl(null)
      setInformationHoverIsOpen(false)
      setHoverModel('')
    }
  }

  const DisplayInformationBox = () => (
    <Popper 
      open={informationHoverIsOpen || informationPopupIsOpen}
      anchorEl={anchorEl}
      placement={'bottom-start'}
    >
      <Paper className={classes.popper} variant={'outlined'} elevation={18}>
        {informationPopupIsOpen && <h6>Detta kan du få hjälp med att klassificera:</h6>}
        {props.predictionModels.map((model: Model) => {
        
          if(informationPopupIsOpen || hoverModel === model.projectId) {
            return (
              <div key={model.id}>
                <span><b>{model.categoryName}</b>: </span>
                <>
                  {model.cvTags?.map((t, i) => (
                    <>
                      <span key={i}>{t.name}</span>
                      {(i === model.cvTags.length - 1) ? <span>.</span> : <span>, </span>}
                    </>
                  ))}
                </>
              </div>
            )}
        })}
      </Paper>
    </Popper>
  )

  const DisplayIdentifyForm = () => (
    <div className={classes.form}>

      {displayFileUpload()}
      {displaySelectedFiles()}

      <div className={classes.modelsSelect}>
        {props.predictionModels.length === 0 && (
          <CircularProgress className={classes.spinner}/>
        )}
        <div className={classes.title}>
          <ClickAwayListener onClickAway={toggleInformationBox}>
            <div>
              <span>Välj kategori </span><span id={'info'} onClick={toggleInformationBox}><Icon component={InfoIcon}></Icon></span>
            </div>
          </ClickAwayListener>
          {DisplayInformationBox()}
        </div>
        <div className={classes.modelsButtons}>
          {props.predictionModels.length > 0 && props.predictionModels.map((model: Model) => (
            <div key={model.id}
              aria-owns={informationHoverIsOpen ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              id={model.projectId}
              onMouseEnter={hoverModelInfoEnter}
              onMouseLeave={hoverModelInfoLeave}
              style={{marginBottom: '20px'}}
            >
              <Button
                variant='outlined' 
                id='mouse-over-popover'
                disabled={filesToUpload.length === 0}
                value={model.id} 
                onClick={handleModelSelect}
              >{model.categoryName}</Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
  
  return (
    <>
      {(appContext.hasRole('superuser') || appContext.hasRole('developer') || appContext.hasRole('klaraadmin')) ?
        <Paper className={classes.uploadPaper} elevation={3}>
          {props.uploadProgress !== null && (
            <UploadProgress progress={props.uploadProgress} />
          )}
          <div className={classes.formContent}>
            {props.predictionMode 
              ? <h2>Identifiera bild</h2> 
              : <h2>Ladda upp bild</h2>}
            <SwitchButton label='Uppladdning / Identifiering' onChange={props.toggleUploadMode} checkDefaultA={true}/>
            {props.predictionMode ? 
              DisplayIdentifyForm() :
              DisplayUploadForm()
            }
          </div>
        </Paper>
        :
        <Paper className={classes.uploadPaper} elevation={3}>
          {props.uploadProgress !== null && (
            <UploadProgress progress={props.uploadProgress} />
          )}
          <div className={classes.formContent}>
            <h2>Identifiera bild</h2> 
            {DisplayIdentifyForm()}
          </div>
        </Paper>
      }

      <ConfirmModal
        isOpen={confirmModalOpen}
        closeModal={() => setConfirmModalOpen(false)}
        title={'Ladda upp'}
        body={props.predictionMode 
          ? 'Vill du ladda upp bild för identifiering?' 
          : 'Vill du ladda upp ' + filesToUpload.length + ((filesToUpload.length === 1) ? ' bild' : ' bilder') + ' till Klara?'}
        cancelText={'Avbryt'}
        confirmText={props.predictionMode ? 'Identifiera bild' : 'Ladda upp'}
        handleConfirm={handleConfirmSubmit}
      />
    </>
  )
}

export default UploadPage