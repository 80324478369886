import React, { useMemo, useState } from 'react'
import { Prediction, PredictionData } from '../../models/Prediction'
import httpClient from '../../HttpClient'
import { Paper } from '@material-ui/core'
import classes from './PredictionResult.module.scss'
import { Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { AxisBottom } from '@visx/axis'
import { useEffect } from 'react'
import { UploadedImage } from '../../models/UploadedImage'

interface Props {
  prediction: Prediction
  pIndex: number
}

export const PredictionResult: React.FC<Props> = (props) => {
  const {prediction, pIndex} = props
  const predictionData = prediction.results

  const [imageData, setImageData] = useState<{items: UploadedImage[], totalItems: number, totalPages: number}>({ items: [], totalItems: 0, totalPages: 0 })

  const verticalMargin = 120
  const width = 300
  const height = 200
  // bounds
  const xMax = width
  const yMax = height - verticalMargin

  const getPercentage = (p: PredictionData) => p.predictionPercentage*100
  const getPercentageText = (p: PredictionData) => Math.round(p.predictionPercentage*100) + '%'

  // move out?
  const getImagesByLabel = async (label: string, numberOfImages: number) => {

    const page = 0 // TODO: not really relevant, make as optional parameter?
    const data = await httpClient.getWithHeaders(`/images/uploaded/label/${label}/${numberOfImages}/${page}`)
    if(!data || !data.items) {
      setImageData({ items: [], totalItems: 0, totalPages: 0 })
      return
    }
    
    setImageData(data)
  }

  useEffect(() => {
    const numberOfExampleImages = 3
    getImagesByLabel(prediction.results[0].predictedTagResult, numberOfExampleImages)
  },[])
  
  const DisplayDbContent = () => (
    <div>
      <Paper className={classes.dataPaper} elevation={3}>
        {
          imageData.items.length > 0 ?
            <>
              <p>Nedan visas några bilder av {prediction?.results[0].predictedTagResult}.</p>
              {imageData.items
                .map((f: UploadedImage) => 
                  <img key={f.filePath} src={f.thumbnailPath || f.filePath} height="200" style={{maxWidth: '100%', objectFit: 'cover', margin: 5}}/>
                )
              }
            </>
            : <p>Inga bilder tillgängliga</p>
        }
      </Paper>
    </div>
  )

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: predictionData.map((p: PredictionData) => p.predictedTagResult),
        padding: 0.4,
      }),
    [xMax],
  )
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...predictionData.map(getPercentage))],
      }),
    [yMax],
  )

  //style 
  const colorWhite = '#fff'
  const defaultMargin = { top: 60, right: 0, bottom: 0, left: 0 }

  const displayChart = () => {
    return width < 10 ? null : (
      <svg width={width} height={height} key={pIndex}>
        <rect width={width} height={height} fill="url(#teal)" rx={14} />
        <Group top={verticalMargin / 2} >
          {predictionData.map((p, idx: number) => {
            const barWidth = xScale.bandwidth()
            const barHeight = yMax - (yScale(getPercentage(p)) ?? 0)
            const barX = xScale(p.predictedTagResult)
            const barY = yMax - barHeight
            return (
              <>
                <Bar
                  key={`bar-${p.predictedTagResult}-${idx}`}
                  className={classes.bar}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill="rgb(139, 182, 57)"
                  onClick={() => {
                    console.log(`clicked: ${JSON.stringify(Object.values(p))}`)
                  }}
                />
                <text
                  key={`text-${p.predictedTagResult}-${idx}`}
                  fill="white"
                  x={barX ? barX + barWidth/2 : barX}
                  y={barY - 10}
                  dy=".33em"
                  fontSize={10}
                  textAnchor="middle"
                  pointerEvents="none"
                >
                  {getPercentageText(p)}
                </text>
              </>
            )
          })}
        </Group>
        <AxisBottom
          top={yMax + defaultMargin.top}
          scale={xScale}
          tickFormat={tag => tag.toUpperCase()}
          stroke={colorWhite}
          tickStroke={colorWhite}
          tickLabelProps={() => ({
            fill: colorWhite,
            fontSize: 11,
            textAnchor: 'middle',
          })}
        />
      </svg>
    )

  }

  return (
    <div className={classes.root} key={pIndex}>
      <div className={classes.image}>
        <img src={prediction.predictedImageUrl} />
      </div>
      <div className={classes.resultInformation}>
        <p>{prediction.results[0].predictedTagResult.toUpperCase()}</p>
        {displayChart()}
      </div>
      {DisplayDbContent()}
    </div>
  )
  
}