import React from 'react'
import { UploadedImage } from '../../../models/UploadedImage'
import { Button, Icon, Paper } from '@material-ui/core'
import classes from './ReviewImage.module.scss'
import CheckIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'

interface Props {
  image: UploadedImage
  onLabelEdit: (img: UploadedImage) => void
  onLabelConfirm: (img: UploadedImage) => void
  onClickImage: (img: UploadedImage) => void
}

const ReviewImage: React.FC<Props> = (props) => {
  const {image} = props

  const editLabelClicked = () => {
    props.onLabelEdit(image)
  }

  const confirmLabelClicked = () => {
    props.onLabelConfirm(image)
  }

  const imageClicked = () => {
    props.onClickImage(image)
  }

  return (
    <div className={image.removed ? `${classes.item} ${classes.removed}` : `${classes.item} ${classes.visible}`}>
      <Paper className={classes.paper}>
        <div className={classes.label}>
          {image.label}
        </div>
        <div>
          <img src={image.thumbnailPath ? image.thumbnailPath : image.filePath} height="300" style={{maxWidth: '100%', objectFit: 'cover', margin: 5}}
            onClick={imageClicked}/>
        </div>
        <div className={classes.buttons}>
          <Button onClick={editLabelClicked} variant="outlined" color="primary">
            <Icon component={EditIcon} fontSize="large" color="primary">
            </Icon>
            Ändra kategori
          </Button>
          <Button onClick={confirmLabelClicked} variant="contained" color="primary">
            <Icon component={CheckIcon} fontSize="large"></Icon>
            Bekräfta
          </Button>
        </div>
      </Paper>
    </div>
  )
}

export default ReviewImage