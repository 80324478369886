import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, Select, Button, CircularProgress } from '@material-ui/core'
import { MenuItem } from '@material-ui/core'
import { UploadedImage } from '../../../models/UploadedImage'
import ReviewImage from './ReviewImage'
import { Tag } from '../../../models/Tag'
import classes from './ReviewPage.module.scss'
import ImageModal from '../../common/ImageModal'

interface Props {
  loading: boolean
  loadingError: boolean
  data: UploadedImage[]
  updateImageData: (image: UploadedImage, labelId: string) => void
  labelOptions: Tag[]
  undoReview: (image: UploadedImage) => void
  onRemove: (image: UploadedImage, labelId: string) => void
}

const ReviewPage: React.FC<Props> = (props: Props) => {

  const undoOptionDelay = 3000
  const [labelEditIsOpen, setLabelEditIsOpen] = useState<boolean>(false)
  const [selectedLabel, setSelectedLabel] = useState<Tag>()
  const [selectedImage, setSelectedImage] = useState<UploadedImage>()
  const [undoOptionActive, setUndoOptionActive] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [undoInterval, setUndoInterval] = useState<any>()
  const [viewFullsizeImage, setViewFullsizeImage] = useState<boolean>(false)
  const [fullscreenImageUrl, setFullscreenImageUrl] = useState<string>('')
  
  const handleLabelEdit = (img: UploadedImage) => {
    setSelectedImage(img)
    setLabelEditIsOpen(true)
  }

  const handleOnLabelConfirmed = (img: UploadedImage) => {
    setSelectedImage(img)
    handleImageReviewed(img, img.labelId)
  }

  const handleTagChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const tag = props.labelOptions.find(obj => obj.labelId === e.target.value)
    setSelectedLabel(tag || props.labelOptions[0])
  }
  
  const handleDialogClose = () => {
    setSelectedLabel(props.labelOptions[0])
    setLabelEditIsOpen(false)
  }

  const handleDialogConfirm = () => {
    if(selectedImage && selectedLabel) {
      handleImageReviewed(selectedImage, selectedLabel.labelId)
      setLabelEditIsOpen(false)
    }
  }

  const onUndoTimeExpired = (img: UploadedImage, labelId: string) => {
    props.updateImageData(img, labelId)
    setUndoOptionActive(false)
  }
  
  const handleImageReviewed = (img: UploadedImage, labelId: string) => {
    props.onRemove(img, labelId)
    clearInterval(undoInterval)
    setUndoOptionActive(true)
    setUndoInterval(setTimeout(onUndoTimeExpired, undoOptionDelay, img, labelId))
  }

  const undoClicked = () => {
    clearInterval(undoInterval)
    setUndoOptionActive(false)
    if(selectedImage)
      props.undoReview(selectedImage)
  }

  const handleClickedImage = (img: UploadedImage) => {
    setFullscreenImageUrl(img.filePath)
    setViewFullsizeImage(true)
  }

  return (
    <div className={classes.root}>
      {props.loading ?
        <CircularProgress className={classes.userFeedback}/> :
        props.loadingError ? 
          <span className={classes.userFeedback}>Kunde inte ladda bilder</span> :
          <div className={classes.list}>
            {props.data
              .map((img: UploadedImage) => 
                <ReviewImage 
                  key={img.id}
                  image={img}
                  onLabelConfirm={handleOnLabelConfirmed}
                  onLabelEdit={handleLabelEdit}
                  onClickImage={handleClickedImage}
                />
              )}
          </div>
      }
      <Dialog
        open={labelEditIsOpen}
        onClose={handleDialogClose}
        maxWidth="lg">
        <DialogContent>
          <Select
            onChange={handleTagChange} 
            value={selectedLabel?.labelId || ''}
            style={{minWidth: '150px'}}>
            {props.labelOptions.sort((r1: Tag, r2: Tag) => {
              return r1.labelName.localeCompare(r2.labelName, 'sv')
            }).map((option: Tag) => (
              <MenuItem 
                value={option.labelId} 
                key={option.labelId}
              >
                {option.labelName}
              </MenuItem>
            )
            )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} color="primary" onClick={handleDialogClose}>
            {'avbryt'}
          </Button>
          <Button variant={'contained'} color="primary" onClick={handleDialogConfirm}>
            <span>{'ändra'}</span>
          </Button>
        </DialogActions>
      </Dialog>
      {undoOptionActive &&
        <div className={classes.undo}>
          <span>En bild godkänd</span>
          <Button onClick={undoClicked}>avbryt</Button>
        </div>
      }
      <ImageModal
        open={viewFullsizeImage}
        imageUrl={fullscreenImageUrl}
        onClose={() => setViewFullsizeImage(false)}/>
    </div>
  )
}

export default ReviewPage