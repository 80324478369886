import { CircularProgress, Icon, Snackbar } from '@material-ui/core'
import React, {useState} from 'react'
import { Model } from '../../../models/Model'
import classes from './MLAdminPage.module.scss'
import { Button } from '@material-ui/core'
import { CVTag } from '../../../models/CVTag'
import ImageSyncTableDisplay from '../../common/ImageSyncTableDisplay'
import { ImageDataTableRow } from '../../../models/ImageDataTableRow'
import { Tag } from '../../../models/Tag'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

interface Props {
  loading: boolean
  imageDataRows: ImageDataTableRow[]
  getIterationStatus: (s: string) => void;
  clearImagesWithTag: (s: string, t: string) => void;
  syncImagesWithTag: (s: string, t: string, n: number) => void;
  startTraining: (s: string) => void;
  models: Model[];
  labelCountList: any,
  allLabels: Tag[],
  snackbarText: string
  showSnackbar: boolean,
  onCloseSnackbar: () => void
  publishModel: (s: string) => void;
}

const MLAdminPage: React.FC<Props> = (props) => {

  const [quantityToSync, setQuantityToSync] = useState<number>(50)

  const DatasetCreation = () => (
    <div className={classes.adminModal}>

      <div style={{margin: 5}}>
        <label>
        Ladda upp 
          <input type="text"
            name="datasetname" 
            value={quantityToSync} 
            onChange={(e) => setQuantityToSync(parseInt(e.target.value))}
            style={{marginLeft: 10, marginRight: 10}}/>
            bilder databas till träning vid nästa synkning
        </label>
      </div>
    </div>
  )

  const countImages = (tags: CVTag[]) => {

    let count = 0
    for (const tag of tags) {
      count += tag.imageCount
    }
    return count
  }

  const getMean = (tags: CVTag[]) => {

    const count = countImages(tags)
    return Math.floor(count / tags.length)
  }

  const getLowest = (tags: CVTag[]) => {

    const MAXDIFF = 0.20
    const counts = tags.map(t => t.imageCount)
    const maxValue = Math.max(...counts)

    return (
      <>
        {
          tags.map( t => 
            <>
              { ((maxValue - t.imageCount) / maxValue) > MAXDIFF &&
                <p key={t.name}>
                  <span>
                    <Icon component={ErrorOutlineIcon} fontSize={'small'}/>
                  </span>
                  <span>
                    {t.name} har bara {t.imageCount} bilder (en annan har {maxValue}), datamängden är därför obalanserad.
                  </span>
                </p>
              }
            </>
          )
        }
      </>
    )
  }

  const getlabelCountListByCVTag = (label: string) => {
    const labels = props.labelCountList?.map((l: any) => l).flat()
    const foundLabel = labels.find((name: any) => name['item1'].toLowerCase() === label.toLowerCase())
    return foundLabel ? foundLabel['item2'] : null
  }

  const getUniqueTags = (categoryName: string) => {

    const categoryLabels = props.allLabels?.filter((t: Tag) => t.categoryName.toLowerCase() === categoryName.toLowerCase())

    const missingLabels: Tag[] = []
    // prepare all tags already in Custom Vision
    const tags = props.models?.map(m => m.cvTags).flat() 

    categoryLabels.forEach((element: Tag) => {
      // uploaded labelName to Custom Vision must be correct
      const found = tags.find(t => t.name.toLowerCase() === element.labelName.toLowerCase())
      if(!found)
        missingLabels.push(element)
    })
    return missingLabels
  }

  const DisplayModelInfo = () => (
    <div className={classes.form}>
      {props.models.map((model: Model) => (
        <div className={classes.modelSection} key={model.id}>
          <h2>{model.categoryName}</h2>
          <h4>Totalt {countImages(model.cvTags)} synkade bilder (i snitt {getMean(model.cvTags)} per klass)</h4>
          <div className={classes.modelInfo}>
            <div className={classes.modelTextInfo}>
              {getLowest(model.cvTags)}
            </div>
            <div className={classes.trainingButtons}>
              <Button 
                variant="outlined"
                color="primary"
                onClick={() => props.startTraining(model.projectId)}> Träna modell
              </Button> 
              <br/>
              <Button 
                variant="outlined"
                onClick={() => props.getIterationStatus(model.projectId)}> Se träningsstatus
              </Button> 
              { model.status && <p>Status: {model.status}</p> }
              <Button 
                variant="outlined"
                disabled={model.status !== 'Completed'}
                onClick={() => props.publishModel(model.projectId)}> Publicera modell
              </Button> 
            </div>
          </div>
          <ImageSyncTableDisplay 
            key={model.id}
            rows={GetTableData(model.projectId, model.categoryName)}
            syncImagesWithTag={props.syncImagesWithTag}
            clearImagesWithTag={props.clearImagesWithTag}
            projectId={model.projectId}
            quantityToSync={quantityToSync}
          />
        </div>
      ))}
    </div>
  )

  const GetTableData = (projectId: string, categoryName: string): ImageDataTableRow[] => {

    const model = props.models.find(m => projectId === m.projectId)
    if(!model)
      return []
    
    const rows: ImageDataTableRow[] = props.imageDataRows.filter((i) => {
      return i.category.toLocaleLowerCase() === model.categoryName.toLocaleLowerCase()
    }) || []
    const missingTagsData = GetMissingTableData(categoryName)
    const allRows = rows.concat(missingTagsData)
    const allRowsSorted = allRows.sort((r1: ImageDataTableRow, r2: ImageDataTableRow) => {
      return r1.name.localeCompare(r2.name, 'sv')
    })

    return allRowsSorted
  }
  
  const GetMissingTableData = (categoryName: string) => {
    
    const labels: Tag[] = getUniqueTags(categoryName)
    
    const rows: ImageDataTableRow[] = []
    labels.forEach((label: Tag) => {
      const row: ImageDataTableRow = {category: categoryName, name: label.labelName, dbQ: (getlabelCountListByCVTag(label.labelName) || 0), syncQ: 0, testQ: 0}
      if(label.categoryName === categoryName)
        rows.push(row)
    })
    return rows
  }

  const handleCloseSnackbar = () => {
    props.onCloseSnackbar()
  }

  return (
    <div className={classes.root}>
      <h2>AI administration</h2>
      {DatasetCreation()}
      {props.loading ? 
        <CircularProgress className={classes.spinner}/>
        :
        <DisplayModelInfo/>}
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        open={props.showSnackbar}
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        message={props.snackbarText}
      />
    </div>
  )
}

export default MLAdminPage