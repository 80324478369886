import * as React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  DialogActions,
  Button
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  isOpen: boolean
  className?: string
  closeModal: () => void
  title: string
  body: string
  cancelText: string
  confirmText: string
  handleConfirm: () => void
}

const ConfirmModal: React.FC<Props> = (props) => {

  const handleConfirm = () => {
    props.handleConfirm()
  }

  return (
    <Dialog
      className={props.className}
      open={props.isOpen}
      onClose={props.closeModal}
      maxWidth="lg"
    >
      <DialogTitle>
        {props.title}
        <Icon
          component={CloseIcon}
          onClick={props.closeModal}
          style={{ float: 'right' }}
        />
      </DialogTitle>
      <DialogContent>
        <p>{props.body}</p>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} color="primary" onClick={props.closeModal}>
          {props.cancelText}
        </Button>
        <Button variant={'contained'} color="primary" onClick={handleConfirm}>
          <span>{props.confirmText}</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmModal