import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#8bb639' // grön
    },
    secondary: {
      main: '#FFEC22' // gul
    },
    background: {
      default: '#349101', // mörkgrön
      paper: '#303030'
    },
    text: {
      primary: '#f5f5f5'
    },
    divider: '#000' // mörkgrön
  }
})

export default responsiveFontSizes(theme)
