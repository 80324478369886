import React, { useEffect, useState } from 'react'
import httpClient from '../../../HttpClient'
import { CategoryImageCount } from '../../../models/CategoryImageCount'
import { LabelImageCount } from '../../../models/LabelImageCount'
import { Category, Tag } from '../../../models/Tag'
import { UploadedImage } from '../../../models/UploadedImage'
import CreateLabelForm from './CreateLabelForm'
import DatabasePage from './DatabasePage'
import classes from './DatabasePage.module.scss'

const DatabasePageContainer: React.FC = () => {

  const [selectedFolder, setSelectedFolder] = useState<string>('')

  const [imageData, setImageData] = useState<{items: UploadedImage[], totalItems: number, totalPages: number}>({ items: [], totalItems: 0, totalPages: 0 })
  const [labelImageCount, setLabelImageCount] = useState<LabelImageCount[]>([])
  const [categoryImageCount, setCategoryImageCount] = useState<CategoryImageCount[]>([])
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null)
  const [categories, setCategories] = useState<Category[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [numImagesPreview, setNumImagesPreview] = useState<number>(5)
  const [labels, setLabels] = React.useState<Tag[]>([])
  const [newLabelExists, setNewLabelExists] = React.useState<boolean>(false)

  useEffect( () => {
    countCategoryImages()
    getCategories()
    getLabels()
  },[])

  useEffect(() => {
    if (categoryImageCount.length && !selectedCategoryId) {
      setSelectedCategoryId(categoryImageCount[0].categoryId)
    }
  }, [categoryImageCount])

  useEffect(() => {
    setLabelImageCount([])
    if (selectedCategoryId) {
      countLabelImages(selectedCategoryId)
    }
  }, [selectedCategoryId, categoryImageCount])

  useEffect(() => {
    if (!selectedFolder) return
    getImagesByLabel(selectedFolder, numImagesPreview, currentPage)
  }, [currentPage, numImagesPreview])

  const getLabels = async () => {

    const labels: Tag[] = await httpClient.getWithHeaders('labels/list')
    if(labels !== undefined)
      setLabels(labels)
  }

  const getCategories = async () => {
    const categories = await httpClient.getWithHeaders('categories/list') as Category[]
    setCategories(categories)
  }

  const getImagesByLabel = async (label: string, numberOfImages: number, page: number) => {

    const data: {items: UploadedImage[], totalItems: number, totalPages: number} = await httpClient.getWithHeaders(`/images/uploaded/label/${label}/${numberOfImages}/${page}`)

    if(!data || !data.items) {
      setImageData({ items: [], totalItems: 0, totalPages: 0 })
      return
    }

    setImageData(data)
  }

  const countCategoryImages = async () => {
    const data = await httpClient.getWithHeaders('/categories/images/count')

    if(!data) {
      setCategoryImageCount([])
      return
    }
    setCategoryImageCount(data)
  }

  const countLabelImages = async (categoryId: number) => {
    const data = await httpClient.getWithHeaders(`/categories/${categoryId}/images/count`)

    if(!data) {
      setLabelImageCount([])
      return
    }
    setLabelImageCount(data)
  }

  const handleSelectFolder = (folderName: string, page: number) => {

    if(folderName !== '') {
      getImagesByLabel(folderName, numImagesPreview, page)
      setSelectedFolder(folderName)
    }
  }
  
  const handleLabelAdded = (categoryId: string, labelName: string) => {
    httpClient.getWithHeaders(`labels/create/${categoryId}/${labelName}`) 
  }

  const handleLabelInputChanged = (labelName: string) => {
    if(!labels.find(l => l.labelName === labelName)) {
      setNewLabelExists(false)
    }
    else {
      setNewLabelExists(true)
    }
  }

  const handleImageDelete = async (image: UploadedImage) => {
    await httpClient.delete(`images/${image.id}`)
    await httpClient.delete(`documents/${encodeURIComponent(image.fileName)}`)
    await getImagesByLabel(selectedFolder, numImagesPreview, currentPage)
    await countCategoryImages()
  }
  
  return (
    <div className={classes.root}>
      <DatabasePage 
        data={imageData} 
        labelImageCount={labelImageCount}
        categoryImageCount={categoryImageCount}
        selectedCategoryId={selectedCategoryId}
        setSelectedCategoryId={setSelectedCategoryId}
        selectedFolder={selectedFolder} selectFolder={handleSelectFolder}
        page={currentPage}
        setPage={setCurrentPage}
        pageSize={numImagesPreview}
        setPageSize={setNumImagesPreview}
        deleteImage={handleImageDelete}
      />
      <CreateLabelForm 
        onLabelAdded={handleLabelAdded} 
        labelExists={newLabelExists}
        onLabelInputChanged={handleLabelInputChanged}
        existingCategories={categories}/>
      
    </div>
  )
}

export default DatabasePageContainer