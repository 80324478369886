import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'

interface Props {
  open: boolean
  imageUrl: string
  onClose: () => void
}

const ImageModal: React.FC<Props> = (props) => {

  const handleOnClose = () => {
    props.onClose()
  }
  return (
    <Dialog
      open={props.open}
      onClose={handleOnClose}
      onBackdropClick={handleOnClose}
      maxWidth={'lg'}>
      <DialogContent>
        <img src={props.imageUrl} style={{objectFit: 'scale-down', maxWidth: '100%'}}/>
      </DialogContent>
    </Dialog>
  )
}

export default ImageModal