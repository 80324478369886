/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@material-ui/core'
import React from 'react'
import { Link, match, useRouteMatch } from 'react-router-dom'

interface Props {
  title: string
  disabled: boolean
  route: string
}

const MenuButton: React.FC<Props> = (props) => {
  const match: match | null = useRouteMatch({
    path: props.route,
    exact: true
  })
  
  const { title, route, disabled } = props

  const ButtonLinkComponent: any = (
    props: { children: any; navigate: any },
    ref: any
  ): any => (
    <Button
      variant={match ? 'contained' : 'outlined'}
      color={'primary'}
      disabled={disabled}
      style={{marginLeft: '5px', marginBottom: '5px', opacity: match ? '0.8' : '1'}}
      ref={ref}
      onClick={(e: any) => {
        e.preventDefault()
        props.navigate()
      }}
    >
      {props.children}
    </Button>
  )

  const CustomLink = React.forwardRef(ButtonLinkComponent)

  return (
    <Link to={route} component={CustomLink}>
      {title}
    </Link>
  )
}

export default MenuButton
