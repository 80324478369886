/* eslint-disable @typescript-eslint/no-explicit-any */

class HttpClient {

    public apiBase: string

    public getAccessToken: () => Promise<string | null> = () => Promise.resolve(null)
  
    constructor(baseUrl?: string) {
      this.apiBase = baseUrl || process.env.REACT_APP_API_BASE || ''
    }

    public uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
  
    public async get(route: string): Promise<any> {
      let responseData
      const url = this.buildUrl(route)
      const accessToken = await this.getAccessToken()

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessControlAllowOrigin: 'no-cors',
            'Content-Type': 'application/json'
          }
        })
  
        responseData = await response.json()
      } catch (e) {
        console.error(e)
      }
  
      return responseData
    }

    public async getWithHeaders(route: string): Promise<any> {
      let responseData
      const url = this.buildUrl(route)
      const accessToken = await this.getAccessToken()
      
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessControlAllowOrigin: 'no-cors',
            'Content-Type': 'application/json'
          }
        })

        responseData = await response.json()
      } catch (e) {
        console.error(e)
      }
  
      return responseData
    }

    public async patchWithHeaders(route: string, data: any): Promise<any> {
      let responseData
      const url = this.buildUrl(route)
      const accessToken = await this.getAccessToken()
      
      try {
        const response = await fetch(url, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            AccessControlAllowOrigin: 'no-cors'
          },
          body: JSON.stringify(data)
        })
        responseData = await response.json()
      } catch (e) {
        console.error(e)
      }
  
      return responseData
    }

    public async postFormData(
      route: string,
      data: FormData,
      contentType: string
    ): Promise<any> {
      let responseData
      const url = this.buildUrl(route)
      const accessToken = await this.getAccessToken()
      
      try {
        const response = await fetch(url, {
          method: 'POST',
  
          // Let the browser set the Content-Type with correct boundary
          headers: {
            Authorization: `Bearer ${accessToken}`,
            ContentType: `${contentType}`
          },
  
          body: data
        })
  
        responseData = await response.json()
      } catch (e) {
        console.error(e)
      }
  
      return responseData
    }
  
    public async delete(route: string): Promise<any> {
      let responseData
      const url = this.buildUrl(route)
      const accessToken = await this.getAccessToken()

      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessControlAllowOrigin: 'no-cors',
            'Content-Type': 'application/json'
          }
        })
  
        responseData = await response.json()
      } catch (e) {
        console.error(e)
      }
  
      return responseData
    }

    public buildUrl(route: string): string {
      const fixedRoute = this._ensureRouteBeginsWithForwardSlash(route)
      return this.apiBase + fixedRoute
    }

    private _ensureRouteBeginsWithForwardSlash(route: string): string {
      const regex = /^\//
      return route.match(regex) === null ? `/${route}` : route
    }
  
}

/**
 * Can be used to create an instance with a different api url
 * Implement headers when needed
 * @param baseUrl [string] e.g. localhost:7071/api
 */
export const createHttpClient = (baseUrl?: string): HttpClient => {
  return new HttpClient(baseUrl)
}
  
const defaultHttpClient = createHttpClient()
  
export default defaultHttpClient
  