import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Button, Fade, Icon, Popover, Popper } from '@material-ui/core'
import { ImageDataTableRow } from '../../models/ImageDataTableRow'
import httpClient from '../../HttpClient'
import ConfirmModal from '../pages/UploadPage/ConfirmModal'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

interface Props {
  projectId: string;
  rows: ImageDataTableRow[];
  syncImagesWithTag: (s: string, t: string, n: number) => any;
  clearImagesWithTag: (s: string, t: string) => any;
  quantityToSync: number;
}

const ImageSyncTableDisplay: React.FC<Props> = (props) => {

  const classes = useStyles()
  const [informationHoverIsOpen, setInformationHoverIsOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<any>(null)

  const toggleInformationHoverBox = (event: React.MouseEvent<HTMLElement>, open: boolean) => {
    if(open) {
      setAnchorEl(event.currentTarget)
      setInformationHoverIsOpen(true)
    } else {
      setAnchorEl(null)
      setInformationHoverIsOpen(false)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hoverModelInfoEnter = (e: any) => {
    toggleInformationHoverBox(e, true)
  }
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hoverModelInfoLeave = (e: any) => {
    toggleInformationHoverBox(e, false)
  }

  const DisplayInformationBox = () => (
    <Popper 
      open={informationHoverIsOpen}
      anchorEl={anchorEl}
      placement={'bottom-start'}>
      <Fade in={informationHoverIsOpen}>
        <Paper elevation={10}>
          <p style={{fontSize: '1.1em'}}>Testbilder används för att utvärdera KLARA och kan inte synkas för träning.</p>
        </Paper>
      </Fade>
    </Popper>
  )

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tagg</TableCell>
              <TableCell align="right">I databas</TableCell>
              <TableCell align="right">Synkade för träning</TableCell>
              <TableCell align="right"
                onMouseEnter={hoverModelInfoEnter}
                onMouseLeave={hoverModelInfoLeave}>Testbilder   <Icon component={InfoIcon} fontSize='small'></Icon></TableCell>
              <TableCell align="right">Synka för träning</TableCell>
              <TableCell align="right">Rensa träningsdata</TableCell>
              <TableCell align="right">Utvärdera</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => (
              <ImageSyncTableDisplayRow
                key={row.name}
                row={row}
                projectId={props.projectId}
                syncImagesWithTag={props.syncImagesWithTag}
                clearImagesWithTag={props.clearImagesWithTag}
                quantityToSync={props.quantityToSync}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {DisplayInformationBox()}
    </>
  )
}

interface RowProps {
  projectId: string;
  row: ImageDataTableRow;
  syncImagesWithTag: (s: string, t: string, n: number) => any;
  clearImagesWithTag: (s: string, t: string) => any;
  quantityToSync: number;
}
const ImageSyncTableDisplayRow: React.FC<RowProps> = (props) => {

  const [evaluation, setEvaluation] = React.useState<string>('')
  const [confirmModalOpen, setConfirmModalOpen] = React.useState<boolean>(false)
  const [confirmSyncModalOpen, setConfirmSyncModalOpen] = React.useState<boolean>(false)
  const [isEvaluating, setIsEvaluating] = React.useState<boolean>(false)

  const firstUpper = (str: string) => {
    return str[0].toUpperCase() + str.substring(1)
  }

  const handleConfirmSubmit = () => {
    setConfirmModalOpen(false)
    props.clearImagesWithTag(props.projectId, props.row.name)
  }

  const handleConfirmSync = () => {
    setConfirmSyncModalOpen(false)
    props.syncImagesWithTag(props.projectId, props.row.name, props.quantityToSync) // use quantitytosync
  }

  const getEvaluation = async (projectGuid: string, labelId: string) => {

    setIsEvaluating(true)
    const data = await httpClient.getWithHeaders(`ai/evaluate/batch/${projectGuid}/${labelId}`)
    if(data) {
      setEvaluation('Utvärdering ' + labelId + ': ' + data.numCorrect + ' rätt av ' + data.numTestFiles + ' möjliga')
      setIsEvaluating(false)
    } else {
      setEvaluation('Utvärdering misslyckades')
      setIsEvaluating(false)
    }
  }

  return (
    <>
      <TableRow key={props.row.name}>
        <TableCell component="th" scope="row">
          {firstUpper(props.row.name)}
        </TableCell>
        <TableCell align="right">{props.row.dbQ}</TableCell>
        <TableCell align="right">{props.row.syncQ}</TableCell>
        <TableCell align="right">{props.row.testQ}</TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            disabled={props.row.dbQ < 1}
            onClick={() => setConfirmSyncModalOpen(true)}> Synka
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            disabled={props.row.syncQ < 1}
            onClick={() => setConfirmModalOpen(true)}> Rensa
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            disabled={props.row.testQ < 1}
            onClick={() => getEvaluation(props.projectId, props.row.name)}>
            {isEvaluating ? 'Utvärderar...' : 'Utvärdera'}
          </Button>
        </TableCell>
      </TableRow>
      { evaluation.length > 0 &&
        <TableRow>
          <TableCell colSpan={7} align="center">
            <p>{evaluation}</p>
          </TableCell>
        </TableRow>
      }
      <ConfirmModal
        isOpen={confirmModalOpen}
        closeModal={() => setConfirmModalOpen(false)}
        title={'Rensa träningsdata'}
        body={'Är du säker på att du vill nollställa mängden bilder som är uppladdade för träning av klassen ' + props.row.name + '?'}
        cancelText={'Avbryt'}
        confirmText={'Ja, rensa'}
        handleConfirm={handleConfirmSubmit}
      />
      <ConfirmModal
        isOpen={confirmSyncModalOpen}
        closeModal={() => setConfirmSyncModalOpen(false)}
        title={'Synka träningsdata'}
        body={'Är du säker på att du vill synka mängden bilder som är uppladdade för träning av klassen ' + props.row.name + '?'}
        cancelText={'Avbryt'}
        confirmText={'Ja, synka'}
        handleConfirm={handleConfirmSync}
      />
    </>
  )
}

export default ImageSyncTableDisplay
