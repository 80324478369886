import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { Prediction } from '../../models/Prediction'
import { PredictionResult } from './PredictionResult'
import classes from './PredictionResultList.module.scss'

interface Props {
    predictedImages: Prediction[]
    loading: boolean
    message?: string
}

export const PredictionResultList: React.FC<Props> = (props) => {
  const {predictedImages, loading, message} = props
  return (
    <div className={classes.root}>

      {loading && <CircularProgress className={classes.spinner}/>}

      {predictedImages.length > 0 &&
      <>
        <h5>Results:</h5>
        <p> {message} </p>
        {predictedImages.map((p: Prediction, idx: number) => (
          p &&
          <PredictionResult prediction={p} pIndex={idx} key={idx}></PredictionResult>
        )
        )}
      </>
      }
    </div>
  )
}