import React, { useState } from 'react'
import httpClient from '../../../HttpClient'
import MLAdminPage from './MLAdminPage'
import { Model } from '../../../models/Model'
import { CVTag } from '../../../models/CVTag'
import { Category } from '../../../models/Tag'
import { ImageDataTableRow } from '../../../models/ImageDataTableRow'
import useFetch from 'use-http'

// PRIO
// TODO: reload on sync or clear (någon typ av respons)

// TODO: choose iteration to have as active model


const MLAdminPageContainer: React.FC = () => {

  const [models, setModels] = React.useState<Model[]>([])
  const [allLoading, setAllLoading] = useState<boolean>(true)
  const [snackbarText, setSnackbarText] = useState<string>('')
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [imageDataRows, setImageDataRows] = useState<ImageDataTableRow[]>([])
   
  const { get: getCategoryList, loading: loadingCategories } = useFetch<Category[]>('categories/list') 
  const { get: getModelList, loading: loadingModels } = useFetch<Model[]>('models/list') 
  const { loading: loadingLabels, data: labels = [] } = useFetch('labels/list', []) 
  const { loading: loadingLabelsCount, data: labelCountList = [] } = useFetch('/images/uploaded/labelscount', []) 

  React.useEffect(() => {
    if(!loadingLabelsCount)
      getModels()
  }, [loadingLabelsCount])
  
  const getIterationStatus = async (projectGuid: string) => {

    const data = await httpClient.getWithHeaders(`ai/trainingstatus/${projectGuid}/0`)

    if(!data) {
      setModels([])
      return
    }

    // TODO: make use of result
    const current = models.find(m => m.projectId === projectGuid)
    
    if(current)
    {
      // find model in list
      const tempList: Model[] = Object.assign([], models)
      const index = models.findIndex(model => model.id === current.id)

      // update model state
      current.status = data.status
      tempList[index] = current
      setModels(tempList)
    }
  }

  const publishModel = async (projectGuid: string) => {
    const data = await httpClient.getWithHeaders(`ai/publish/${projectGuid}`)
  }

  const startTraining = async (projectGuid: string) => {

    setSnackbarText('Kontaktar KLARA för träning...')
    setShowSnackbar(true)
    
    const data = await httpClient.getWithHeaders(`ai/starttraining/${projectGuid}`)
    if(data?.response)
      setSnackbarText('Träning påbörjad, detta kan ta några minuter.')
    else 
      setSnackbarText('Träning startades inte.')
  }

  const getModels = async () => {

    // prepare each model
    const cats = await getCategoryList() 
    const fetchedModels = await getModelList()

    if(!fetchedModels)
      return []

    const newModels: Model[] = await Promise.all(fetchedModels.map(async (m: Model) => {

      const category = cats.find((c: {categoryName: string, id: string}) => c.id == m.categoryIds)// e.g. 'grass-weeds'
      const tagData = await httpClient.getWithHeaders(`ai/listtags/${m.projectId}`)

      // sub-request for custom vision tags and quantities per model
      const tags: CVTag[] = []
      
      tagData?.projects.map((t: any) => {
        tags.push({
          name: t.name,
          imageCount: t.imageCount,
        } as CVTag)
      })
      
      const newModel: Model = ({
        modelName: m.modelName,
        id: m.id,
        categoryName: category?.categoryName || '',
        projectId: m.projectId,
        cvTags: tags,
        iteration: 0,
        status: '',
      }) as Model
      
      return newModel
    }))
    const modelsSorted = newModels.sort((m, n) => m.categoryName > n.categoryName ? 1 : -1 )
    getDataRows(modelsSorted, cats)
    setModels(modelsSorted)
  }

  const getDataRows = async (dataModels: Model[], cats: Category[]) => {

    let allRows: ImageDataTableRow[] = []
    await dataModels.forEach(async (m: Model, i: number) => {
      const tagData = await httpClient.getWithHeaders(`ai/listtags/${m.projectId}`)
      const category: Category | undefined = cats.find((c: {categoryName: string, id: string}) => c.categoryName == m.categoryName)// e.g. 'grass-weeds'

      Promise.all(tagData.projects.map(async (t: any) => {
        const testQuantity = await getTestQuantityByCVTag(t.name) || 0
        return {
          category: category?.categoryName || '',
          name: t.name,
          dbQ: getlabelCountListByCVTag(t.name) || 0,
          syncQ: t.imageCount,
          testQ: testQuantity
        } as ImageDataTableRow
      })).then((rows) => {
        allRows = allRows.concat(rows as ImageDataTableRow[])
        setImageDataRows(allRows)
        if(i === dataModels.length - 1)
          setAllLoading(false)
      })
    })
  }

  const getlabelCountListByCVTag = (label: string) => {
    const labels = labelCountList.map((l: any) => l).flat()
    const foundLabel = labels.find((name: any) => name['item1'].toLowerCase() === label.toLowerCase())
    return foundLabel ? foundLabel['item2'] : null
  }

  const getTestQuantityByCVTag = async (labelIdOrName: string) => {
    const data = await httpClient.getWithHeaders(`images/test/${labelIdOrName}`)
    return data ? data.length : 0
  }

  const syncImagesWithTag = async (projectGuid: string, tag: string, quantity: number) => {

    setSnackbarText(`Synkar bilder av ${tag} hos KLARA...`)
    setShowSnackbar(true)
    
    const result = await httpClient.getWithHeaders(`ai/uploadimagesfromdb/${projectGuid}/${tag}/${quantity}`)
    if(result?.response)
      setSnackbarText('Synkning lyckad')
    else 
      setSnackbarText('Synkningen misslyckades')
  }

  const clearImagesWithTag = async (projectGuid: string, tag: string) => {

    setSnackbarText(`Rensar bilder av ${tag} hos KLARA...`)
    setShowSnackbar(true)

    const result = await httpClient.getWithHeaders(`ai/cleardata/${projectGuid}/${tag}`)
    if(result?.response)
      setSnackbarText('Rensning lyckad')
    else 
      setSnackbarText('Rensningen misslyckades')
  }

  const handleCloseSnackbar = () => {
    setShowSnackbar(false)
    setSnackbarText('')
  }

  return (
    <>
      <MLAdminPage 
        loading={allLoading || loadingLabels || loadingLabelsCount || loadingCategories || loadingModels}
        imageDataRows={imageDataRows}
        getIterationStatus={getIterationStatus}
        models={models}
        labelCountList={labelCountList}
        clearImagesWithTag={clearImagesWithTag}
        syncImagesWithTag={syncImagesWithTag}
        startTraining={startTraining}
        allLabels={labels}
        snackbarText={snackbarText}
        showSnackbar={showSnackbar}
        onCloseSnackbar={handleCloseSnackbar}
        publishModel={publishModel}
      />
    </>
  )
}
export default MLAdminPageContainer