import { Button } from '@material-ui/core'
import React from 'react'
import logo_src from '../../assets/klara_logo_white.png'
import classes from './LandingPage.module.scss'

interface Props {
    onLogin: () => void
}

const LandingPageContainer: React.FC<Props> = (props) => {
  
  const handleLogin = () => {
    props.onLogin()
  }
  
  return (
    <div className={classes.root}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img src={logo_src} style={{width: 100}}></img>
      </div>
      <div className={classes.mainContent}>
        <h1>
          Welcome to KLARA AI
        </h1>
        <Button variant="contained" color="primary" onClick={handleLogin}>Logga in</Button>
      </div>
    </div>
  )
}

export default LandingPageContainer