import React, { useState } from 'react'
import { AppContext } from '../../../appContext'
import httpClient from '../../../HttpClient'
import { FileToUpload } from '../../../models/FileToUpload'
import { Model } from '../../../models/Model'
import { CVTag } from '../../../models/CVTag'
import { Prediction, PredictionData } from '../../../models/Prediction'
import { Tag } from '../../../models/Tag'
import { PredictionResultList } from '../../common/PredictionResultList'
import UploadPage from './UploadPage'

enum UploadMode {
  Upload,
  Predict,
} 

const UploadPageContainer: React.FC = () => {
  
  const [loading, setLoading] = useState(false)
  const [taggingOptions, SetTaggingOptions] = useState<Tag[]>([])
  const [predicted, setPredicted] = useState('')
  const [uploadMode, setUploadMode] = useState<UploadMode>(UploadMode.Predict)
  const [predictedImages, setPredictedImages] = useState<Prediction[]>([])
  const [models, setModels] = React.useState<Model[]>([])
  const resultRef = React.useRef<HTMLDivElement>(null)
  const appContext = React.useContext(AppContext)
  const [uploadProgress, setUploadProgress] = React.useState<number | null>(null)

  React.useEffect(() => {
    getTagOptions()
    getModels()
  }, [])

  React.useEffect(() => {
    if(predictedImages.length > 0) {
      setTimeout(function () {
        if(resultRef !== null && resultRef.current && resultRef.current.offsetTop) 
        {
          resultRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      },3)
    }
  }, [predictedImages])


  const onFileUpload = async (files: FileToUpload[]) => {
    setLoading(true)
    setUploadProgress(0)
    setPredictedImages([])
    if(files)
      UploadViaAzureFunction(files)
  }

  const onFilePredict = async (files: FileToUpload[], modelId: string) => {
    setLoading(true)
    setPredictedImages([])
    if(files)
      predictFiles(files, modelId)
  }

  const toggleUploadMode = (checked: boolean) => {

    setPredicted('')
    setPredictedImages([])

    setUploadMode(checked ? UploadMode.Predict : UploadMode.Upload)
  }

  const getTagOptions = async () => {

    const labels: Tag[]  = await httpClient.getWithHeaders('labels/list')
    
    if(labels !== undefined) {
      const labelsSorted = labels.sort((r1: Tag, r2: Tag) => {
        return r1.labelName.localeCompare(r2.labelName, 'sv')
      })
      SetTaggingOptions(labelsSorted)
    }
  }

  const getModels = async () => {
    const modelsData = await httpClient.getWithHeaders('models/list')
    const categoryData = await httpClient.getWithHeaders('categories/list')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    if(!modelsData)
      return

    const models: Model[] = await Promise.all(modelsData.map(async (m: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cat = categoryData?.find((c: any) => c.id == m.categoryIds.toString())

      //get tags
      const tagData = await httpClient.getWithHeaders(`ai/listtags/${m.projectId}`) 
      const tags: CVTag[] = []
      if(tagData) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tagData?.projects?.map((t: any) => {
          tags.push({
            name: t.name,
            imageCount: t.imageCount,
          })
        })
      }

      return ({
        modelName: m.modelName,
        id: m.id,
        categoryName: cat?.categoryName || '',
        projectId: m.projectId,
        cvTags: tags,
        iteration: 0,
        status: ''
      })
    }))
    setModels(models)
  }

  const onGetModelTags = async (modelId: string, projectId: string) => {
    const tagData = await httpClient.getWithHeaders(`ai/listtags/${projectId}`)   
    const tags: CVTag[] = []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tagData?.projects?.map((t: any) => {
      tags.push({
        name: t.name,
        imageCount: t.imageCount,
      })
    })

    const current = models.find(m => m.id === modelId)
    if(current)
    {
      // find model in list
      const tempList: Model[] = Object.assign([], models)
      const index = models.findIndex(model => model.id === current?.id)

      // update model state
      current.cvTags = tags
      tempList[index] = current
      setModels(tempList)
    }
  }

  const UploadViaAzureFunction = async (files: FileToUpload[]) => {
    let uploaded = 0
    try {
      await Promise.all(files.map(async (file: FileToUpload, index) => {
        const UUID = httpClient.uuidv4()
        const formData = new FormData()
        formData.append('file', file.file || '')
        formData.append('prettyname', file.primaryTag.labelName.toLowerCase())
        formData.append('tag', file.primaryTag.labelName)
        formData.append('tagId', file.primaryTag.labelId)
        formData.append('blobId', UUID)
        formData.append('parentTag', file.primaryTag.categoryName)
        formData.append('userInfo', appContext.account?.username || '')
        formData.append('secondaryTags', JSON.stringify(file.secondaryTags))
        formData.append('isApproved', JSON.stringify(true))
  
        await uploadImage(formData)
        setUploadProgress((++uploaded / files.length) * 100)
      }))
    } finally {
      setTimeout(() => {
        setLoading(false)
        setUploadProgress(null)
      }, 1000)
    }
  }

  const predictFiles = async (files: FileToUpload[], modelId: string) => {

    if(!files) return
    Promise.all(files.map(async (file: FileToUpload) => {
      const UUID = httpClient.uuidv4()
      const formData = new FormData()
      formData.append('file', file.file || '')
      formData.append('blobId', UUID)
      formData.append('userInfo', appContext.account?.username || '')
      formData.append('secondaryTags', JSON.stringify(file.secondaryTags))
      const img: Prediction | null = await predictImage(formData, file.objectURL, modelId)
      
      if(!img)
        return

      const imgLabel = taggingOptions.find(x => x.labelName.toLocaleLowerCase() == img.results[0].predictedTagResult)
      
      if(imgLabel && img.results[0].predictionPercentage > 0.29) {
        formData.append('tag', imgLabel.labelName || '')
        formData.append('tagId', imgLabel.labelId || '')
        formData.append('prettyname', imgLabel.labelName.toLowerCase() || '')
        formData.append('parentTag', imgLabel.categoryName || '')
        formData.append('isApproved', JSON.stringify(false))
        await uploadImage(formData)
      }

      return img
    })).then((values) => {
      setPredictedImages(values as Prediction[])
      setLoading(false)
    }
    )
  }

  const uploadImage = (image: FormData) => {
    return httpClient.postFormData('documents', image, 'image/jpeg')
  }

  const predictImage = async (image: FormData, imageUrl: string, modelId: string) => {
    const responseData = await httpClient.postFormData(`ai/classify/${modelId}` , image, 'image/jpeg')
    if(!responseData && !responseData.result) {
      return null
    }
    const predictions = JSON.parse(responseData.result)
    
    if(predictions?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const highestPredictions: PredictionData[] = predictions.map((p: any) => {
        return({
          predictionPercentage: p.probability,
          predictedTagResult: p.tagName
        } as PredictionData)
      }).slice(0,3)

      const predictedImage: Prediction = {
        predictedImageUrl: imageUrl,
        results: highestPredictions
      }

      return predictedImage
    } else {
      setPredicted('Klara seems to be sleeping at the moment, please contact your administrators.')
      return null
    }
  }

  return (
    <>
      <UploadPage 
        onUploadFiles={onFileUpload} 
        onPredictFiles={onFilePredict}
        onGetModelTags={onGetModelTags}
        tagOptions={taggingOptions} 
        secondaryTagOptions={[]} 
        predictionMode={uploadMode === UploadMode.Predict}
        isUploading={loading}
        toggleUploadMode={toggleUploadMode}
        predictionModels={models}
        uploadProgress={uploadProgress}
      />

      <div ref={resultRef}>
        {uploadMode === UploadMode.Predict &&
          <PredictionResultList predictedImages={predictedImages} loading={loading} message={predicted}/>
        }
      </div>
    </>
  )
}

export default UploadPageContainer