import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

interface Props {
  label: string,
  checkDefaultA: boolean
  onChange: (checked: boolean) => void
}

const SwitchButton: React.FC<Props> = (props) => {

  const [state, setState] = React.useState({
    checkedA: props.checkDefaultA,
    checkedB: !props.checkDefaultA,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked })
    props.onChange(event.target.checked)
  }

  return (
    <FormGroup row>
      {/* <FormControlLabel
        control={<Switch checked={state.checkedB} onChange={handleChange} name='checkedB' />}
        label='Secondary'
      /> */}
      <FormControlLabel
        control={
          <Switch
            checked={state.checkedA}
            onChange={handleChange}
            name='checkedA'
            color='primary'
          />
        }
        label={props.label}
      />
    </FormGroup>
  )
}

export default SwitchButton