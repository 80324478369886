import { LinearProgress } from '@material-ui/core'
import React from 'react'
import classes from './UploadProgress.module.scss'

type Props = {
  progress: number
}

export const UploadProgress: React.FC<Props> = ({
  progress
}: Props) => {
  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={progress} />
      <p>
        {progress < 100
          ? 'Laddar upp... Vänligen lämna inte sidan.'
          : 'Uppladdningen är genomförd.'
        }
      </p>
    </div>
  )
}
