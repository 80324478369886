import { IconButton, MenuItem, Paper, Select } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { CategoryImageCount } from '../../../models/CategoryImageCount'
import { LabelImageCount } from '../../../models/LabelImageCount'
import { UploadedImage } from '../../../models/UploadedImage'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import ImageModal from '../../common/ImageModal'
import classes from './DatabasePage.module.scss'

interface Props {
  data: {items: UploadedImage[], totalItems: number, totalPages: number}
  labelImageCount: LabelImageCount[]
  categoryImageCount: CategoryImageCount[]
  selectedCategoryId: number | null
  setSelectedCategoryId: (id: number) => void
  selectedFolder: string
  selectFolder: (n: string, p: number) => void;
  page: number
  setPage: (page: number) => void
  pageSize: number
  setPageSize: (num: number) => void
  deleteImage: (image: UploadedImage) => void
}

const DatabasePage: React.FC<Props> = (props) => {
  const { selectedFolder = '' } = props
  const lastPage = Math.max(props.data.totalPages - 1, 0)
  const onFirstPage = props.page <= 0
  const onLastPage = props.page >= lastPage
  const [viewFullsizeImage, setViewFullsizeImage] = React.useState<boolean>(false)
  const [fullscreenImageUrl, setFullscreenImageUrl] = React.useState<string>('')
  const [imageToDelete, setImageToDelete] = React.useState<UploadedImage | null>(null)

  React.useEffect(() => {
    const adjustedPage = Math.min(props.page, lastPage)
    if (adjustedPage !== props.page) {
      props.setPage(adjustedPage)
    }
  }, [props.pageSize])

  const handleClickedImage = (e: React.MouseEvent<HTMLImageElement>) => { //React.MouseEvent<>
    setFullscreenImageUrl(e.currentTarget.id)
    setViewFullsizeImage(true)
  }

  const handleDeleteConfirmClose = (result: boolean) => {
    if (result && imageToDelete) {
      props.deleteImage(imageToDelete)
    }

    setImageToDelete(null)
  }

  return (
    <div>
      <h3> Antal bilder per klass: </h3>
      <Paper className={classes.dataPaper} elevation={3}>
        {props.categoryImageCount.length === 0 && (
          <>
            <div className={classes.buttonArea}>
              <Skeleton variant="rect" width={210} height={40} />
              <Skeleton variant="rect" width={210} height={40} />
              <Skeleton variant="rect" width={210} height={40} />
            </div>
            <p>Ingen statistik tillgänglig</p>
          </>
        )}

        {props.categoryImageCount.length > 0 && (
          <Tabs
            activeKey={props.selectedCategoryId}
            onSelect={(index) => props.setSelectedCategoryId(parseInt(index ?? ''))}
          >
            {props.categoryImageCount?.map((categoryRow: CategoryImageCount) => (
              <Tab
                eventKey={String(categoryRow.categoryId)}
                title={`${categoryRow.categoryName} (${categoryRow.count})`}
                key={categoryRow.categoryId}
                tabClassName={classes.tab}
              >
                <div className={classes.tabContent}>
                  {props.labelImageCount.map((labelRow: LabelImageCount) => (
                    <Button key={labelRow.labelId}
                      variant={labelRow.labelName === selectedFolder ? 'light' : 'outline-dark'}
                      onClick={() => props.selectFolder(labelRow.labelName, props.page)}>
                      {labelRow.labelName} ({labelRow.count})
                    </Button>
                  ))}
                </div>
              </Tab>
            ))}
          </Tabs>
        )}
      </Paper>
      <h3> Bilder från databasen: </h3>
      <Paper className={classes.dataPaper} elevation={3}>
        {!props.data.items?.length && (
          <em>Inga bilder tillgängliga. Välj en klass för att visa bilder.</em>
        )}

        {!!props.data.items?.length && (
          <>
            <p>Visar de {props.pageSize} senaste bilderna</p>

            <ul className={classes.imageGrid}>
              {props.data.items.map((f: UploadedImage) => (
                <li key={f.thumbnailPath} className={classes.imageTile}>
                  <img
                    id={f.filePath}
                    src={f.thumbnailPath || f.filePath}
                    height="200"
                    onClick={handleClickedImage}
                  />

                  <div className={classes.imageButtons}>
                    <IconButton onClick={() => setImageToDelete(f)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </li>
              ))}
            </ul>

            <p>Sida: {props.page + 1} / {lastPage + 1}</p>
            <div className={classes.buttonArea}>
              <Button
                variant={onFirstPage ? 'dark' : 'outline-dark'}
                onClick={() => props.setPage(props.page - 1)}
                disabled={onFirstPage}
              >
                Förgående
              </Button>

              <Button
                variant={onLastPage ? 'dark' : 'outline-dark'}
                onClick={() => props.setPage(props.page + 1)}
                disabled={onLastPage}
              >
                Nästa
              </Button>

              <Select value={props.pageSize} onChange={(e) => props.setPageSize(Number(e.target.value))}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </div>
          </>
        )}
      </Paper>
      <ImageModal
        open={viewFullsizeImage}
        imageUrl={fullscreenImageUrl}
        onClose={() => setViewFullsizeImage(false)}/>

      <ConfirmDialog
        title="Radera bild"
        description={`Vill du radera ${imageToDelete?.fileName}?`}
        open={Boolean(imageToDelete)}
        onClose={handleDeleteConfirmClose}
      />
    </div>
  )
}

export default DatabasePage