import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'

type Props = {
  title: string
  description?: string
  confirmLabel?: string
  cancelLabel?: string
  open: boolean
  onClose: (result: boolean) => void
}

const ConfirmDialog: React.FC<Props> = ({
  title,
  description,
  confirmLabel,
  cancelLabel,
  open,
  onClose
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={0}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>

      {!!description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          {cancelLabel ?? 'Avbryt'}
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          {confirmLabel ?? 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ConfirmDialog }
